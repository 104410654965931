<template>
  <div id="user-view" class="thisIsUserProfile">
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <user-bio-panel
          :user-data="userData"
          :is-plan-upgrade-dialog-visible.sync="isPlanUpgradeDialogVisible"
        ></user-bio-panel>
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-tabs
          v-model="userTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <!-- <v-tab-item>
            <user-tab-overview></user-tab-overview>
          </v-tab-item> -->

          <v-tab-item>
            <user-tab-security></user-tab-security>
          </v-tab-item>
          <v-tab-item>
            <user-tab-billings-plans :is-plan-upgrade-dialog-visible.sync="isPlanUpgradeDialogVisible"></user-tab-billings-plans>
          </v-tab-item>
          <v-tab-item>
            <user-tab-notifications></user-tab-notifications>
          </v-tab-item>
          <v-tab-item>
            <user-tab-connections></user-tab-connections>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'

// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiBellOutline, mdiBookmarkOutline, mdiLinkVariant, mdiLockOutline } from '@mdi/js'
import { SimpleCrypto } from 'simple-crypto-js'
import userStoreModule from '../../../../user/list/userStoreModule'
import UserBioPanel from './user-bio-panel/UserBioPanel.vue'

// import UserTabBillingsPlans from './user-tab-billings-plans/UserTabBillingsPlans.vue'
// import UserTabConnections from './user-tab-connections/UserTabConnections.vue'
// import UserTabNotifications from './user-tab-notifications/UserTabNotifications.vue'
// import UserTabOverview from './user-tab-overview/UserTabOverview.vue'
import UserTabSecurity from './user-tab-security/UserTabSecurity.vue'

export default {
  components: {
    UserBioPanel,

    // UserTabOverview,
    UserTabSecurity,

    // UserTabBillingsPlans,
    // UserTabNotifications,
    // UserTabConnections,
  },
  setup() {
    const USER_APP = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP)) store.registerModule(USER_APP, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP)) store.unregisterModule(USER_APP)
    })

    const CryptoSecretKey = 'DetainEHRSecretKeyThatWillAllowUsToBeSuperSecure'
    const SCrypt = new SimpleCrypto(CryptoSecretKey)

    const userData = SCrypt.decrypt(localStorage.getItem('userData'))

    const userTab = ref(null)
    const selectedPlan = ref('')
    const isPlanUpgradeDialogVisible = ref(false)

    store
      .dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => {
        userData.value = response.data
        selectedPlan.value = response.data.currentPlan
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = {}
        }
      })

    const plansList = [
      { text: 'Basic - $0/month', value: 'basic' },
      { text: 'Standard - $99/month', value: 'standard' },
      { text: 'Enterprise - $499/month', value: 'enterprise' },
      { text: 'Company - $999/month', value: 'company' },
    ]

    const tabs = [
      // { icon: mdiAccountOutline, title: 'Overview' },
      { icon: mdiLockOutline, title: 'Security' },

      // { icon: mdiBookmarkOutline, title: 'Billing & Plan' },
      // { icon: mdiBellOutline, title: 'Notifications' },
      // { icon: mdiLinkVariant, title: 'Connections' },
    ]

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    return {
      tabs,
      userTab,
      plansList,
      selectedPlan,
      isPlanUpgradeDialogVisible,
      userData,
      resolveCurrentPlanValue,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
