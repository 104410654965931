<template>
  <div class="user-tab-security">
    <v-card class="mb-7">
      <v-card-title>
        Update Phone Number
      </v-card-title>

      <v-card-text>
        <v-alert
          color="warning"
        >
          <p class="font-weight-semibold mb-1">
            Please enter all ten digits of your phone number.
          </p>
          <p class="text-sm mb-0">
            This format: 1234567890
          </p>
        </v-alert>
        <!-- form -->
        <v-form class="multi-col-validation">
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="newPhoneNumber"
                outlined
                dense
                maxlength="10"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                depressed
                :disabled="phoneNumberBtnIsDisabled"
                @click="beginUpdatePhoneNumber()"
              >
                Update
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <!-- change password -->
    <v-card class="mb-7">
      <v-card-title>
        Change Password
      </v-card-title>

      <v-card-text>
        <v-alert
          color="warning"
          text
        >
          <p class="font-weight-semibold mb-1">
            Ensure that these requirements are met
          </p>
          <p class="text-sm mb-0">
            Minimum 8 characters long, uppercase &amp; symbol
          </p>
        </v-alert>
        <!-- form -->
        <v-form class="multi-col-validation">
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="newPassword"
                outlined
                dense
                label="New Password"
                :type="isPasswordVisible ? 'text' : 'password'"
                :append-icon="isPasswordVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="oldPassword"
                outlined
                dense
                label="Confirm New Password"
                :type="isPasswordConfirmVisible ? 'text' : 'password'"
                :append-icon="isPasswordConfirmVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                @click:append="isPasswordConfirmVisible = !isPasswordConfirmVisible"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                depressed
                :disabled="passwordButtonIsDisabled"
                @click="submitPasswordUpdate()"
              >
                Change Password
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- two step verification -->
    <v-card class="mb-7 pb-7">
      <v-card-title>
        Two-step verification
      </v-card-title>
      <v-card-subtitle>
        Keep your account secure with authentication step. <a
          href="https://support.microsoft.com/en-us/topic/what-is-multifactor-authentication-e5e39437-121c-be60-d123-eda06bddf661"
          class="text-decoration-none"
        >Learn more</a>.
      </v-card-subtitle>

      <div>
        <div>
          <v-sheet
            border="solid"
            width="300"
            class="mx-auto"
          >
            <img
              :src="qrCode"
            />
            <v-form
              v-if="qrCode!==''"
              @submit.prevent
            >
              <v-card-text>
                <v-label>
                  Scan the QR Code into Google Authenticator App and enter the code.
                </v-label>
                <v-text-field
                  v-model="code"
                  label="Auth Code"
                  required
                ></v-text-field>
              </v-card-text>
              <v-btn
                type="submit"
                block
                color="primary"
                class="mt-2"
                @click="Submit()"
              >
                Submit
              </v-btn>
            </v-form>
          </v-sheet>
        </div>
        <div>
          <v-sheet
            width="300"
            class="mx-auto"
          >
            <v-form
              v-if="qrCode===''"
              @submit.prevent
            >
              <v-card-text>
                <v-label>
                  Choose which site you want to enable for MFA.
                </v-label>
                <site-select
                  :site="site"
                  @site-select="updateSite"
                >
                </site-select>
              </v-card-text>
              <v-btn
                type="submit"
                block
                color="primary"
                class="mt-2"
                :disabled="mfaButtonIsDisabled"
                @click="Update()"
              >
                Begin
              </v-btn>
            </v-form>
          </v-sheet>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiSquareEditOutline, mdiDeleteOutline, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import SiteSelect from '@/components/site/SiteSelect.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import authenticationService from '@/services/AuthenticationService'
import useAuthenticationService from '@/store/useAuthenticationService'
import store from '@/store'
import { SimpleCrypto } from 'simple-crypto-js'

export default {
  components: {
    SiteSelect,
  },
  setup() {
    const MFA_APP = 'app-auth'

    // Register module
    if (!store.hasModule(MFA_APP)) {
      store.registerModule(MFA_APP, authenticationService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MFA_APP)) store.unregisterModule(MFA_APP)
    })

    const { updatePassword, mFALogin, mFASetup, EntryDTO, errorMessages, qrCode, updatePhoneNumber } =
      useAuthenticationService()

    const registerForm = ref(null)

    const CryptoSecretKey = 'DetainEHRSecretKeyThatWillAllowUsToBeSuperSecure'
    const SCrypt = new SimpleCrypto(CryptoSecretKey)
    const userData = SCrypt.decrypt(localStorage.getItem('userData'))

    const istwoFactorDialogOpen = ref(false)
    const newPassword = ref('')
    const oldPassword = ref('')
    const smsVerificationNumber = ref('+1(968) 819-2547')
    const isPasswordVisible = ref(false)
    const isPasswordConfirmVisible = ref(false)

    return {
      updatePassword,
      registerForm,
      userData,
      mFALogin,
      mFASetup,
      EntryDTO,
      qrCode,
      errorMessages,
      updatePhoneNumber,
      istwoFactorDialogOpen,
      newPassword,
      oldPassword,
      smsVerificationNumber,
      isPasswordVisible,
      isPasswordConfirmVisible,
      icons: {
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  data() {
    return {
      mfaButtonIsDisabled: true,
      passwordButtonIsDisabled: true,
      enteredOldPass: false,
      enteredNewPass: false,
      phoneNumberBtnIsDisabled: true,
      newPhoneNumber: '',
      code: '',
      site: null,
      siteId: ref(''),
      step: 1,
      requireMFACode: false,
      otpEmailSent: false,
      instructions: '',
      email: this.userData.email,
      emailRules: [
        v => !!v || 'Email is required',
        v => v.length >= 5 || 'The e-mail address must contain at least 5 characters',
        v => v.length <= 50 || 'The e-mail address cannot be longer than 50 characters',
        v => /.+@.+/.test(v) || 'Please enter a valid email address',
      ],
    }
  },
  watch: {
    site(newValue, oldValue) {
      this.mfaButtonIsDisabled = false
    },
    newPassword(newValue, oldValue) {
      const regex = '^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[^a-zA-Zd]).{8,}$'
      const properPasswordFormat = this.newPassword.match(regex)

      if (properPasswordFormat.length > 0) {
        this.enteredNewPass = true
      }

      if (this.enteredNewPass === true && this.enteredOldPass === true) this.passwordButtonIsDisabled = false
    },
    newPhoneNumber(newValue, oldValue) {
      if (this.newPhoneNumber.length === 10) {
        console.log(this.newPhoneNumber)
        this.phoneNumberBtnIsDisabled = false
      }
    },
    oldPassword(newValue, oldValue) {
      const regex = '^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[^a-zA-Zd]).{8,}$'
      const properPasswordFormat = this.oldPassword.match(regex)

      if (properPasswordFormat.length > 0) {
        this.enteredOldPass = true
      }

      if (this.enteredNewPass === true && this.enteredOldPass === true) this.passwordButtonIsDisabled = false
    },
  },
  methods: {
    updateSite(siteDTO) {
      this.site = siteDTO.value
      this.siteId = siteDTO.value.id
    },
    async Update() {
      console.log(`siteDTO.value.id ${this.siteId}`)
      const EntryDTO = {
        Email: this.email,
        Password: 'empty',
        SiteId: this.siteId,
      }

      await this.mFASetup(EntryDTO)
    },
    async Submit() {
      const EntryDTO = {
        Email: this.email,
        Password: this.code,
        SiteId: this.siteId,
      }

      await this.mFALogin(EntryDTO)

      alert('Successful! Now you can use your google authenticator app to login, at any time for this specific site!')

      this.buttonIsDisabled = true
      this.step = 1
    },
    async submitPasswordUpdate() {
      const UpdatePasswordDTO = {
        Email: this.email,
        newPassword: this.newPassword,
        oldPassword: this.oldPassword,
      }

      await this.updatePassword(UpdatePasswordDTO)
    },
    async beginUpdatePhoneNumber() {
      await this.updatePhoneNumber(this.newPhoneNumber)
    },
  },
}
</script>
